<template>
  <div class="view" :class="selected.length && 'with-header'">
    <div class="view-header" v-if="selected.length">
      <select v-model="kid">
        <option value="kim">Kim</option>
        <option value="mao">Mao</option>
        <option value="both">Both</option>
      </select>
    </div>
    <div class="view-inner">
      <div
        v-for="media in identify"
        :key="media._id"
        class="image-container visible"
      >
        <Media
          :media="media"
          details
          :selected="selected.includes(media._id)"
          @press="$event ? open(media) : select(media._id)"
        />
        <div class="media-controls">
          <Icon
              name="trash"
              size="30"
              @click="deleteMedia(media)"
              class="media-control delete-media"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/Icon.vue";
import Media from "../components/Media.vue";

export default {
  name: "Pending",
  components: {
    Icon,
    Media,
  },
  data() {
    return {
      identify: null,
      selected: [],
      kid: null,
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.selected = [];
      this.kid = null;
      this.api.Media.query({ kim: false, mao: false }).then((identify) => {
        this.identify = identify.medias;
      });
    },
    open(media) {
      this.$root.$emit("open-media", {
        medias: [media],
        index: 0,
      });
    },
    select(mediaId) {
      const io = this.selected.indexOf(mediaId);
      if (io === -1) {
        this.selected.push(mediaId);
      } else {
        this.selected.splice(io, 1);
      }
    },
    setKid() {
      this.api.Media.identify({
        medias: this.selected,
        kim: this.kid === "kim" || this.kid === "both",
        mao: this.kid === "mao" || this.kid === "both",
      })
        .then(() => {
          this.refresh();
        })
        .catch((err) => {
          alert("Identify error: " + err);
        });
    },
    deleteMedia(media) {
      if (confirm("Are you sure you want to delete this file?")) {
        this.api.Media.destroy(media._id)
            .then(() => {
              this.refresh();
              alert("Successfully deleted");
            })
            .catch((err) => {
              alert("Delete error: " + err);
            });
      }
    },
  },
  watch: {
    kid(value) {
      if (value) {
        this.setKid();
      }
    },
  },
};
</script>
