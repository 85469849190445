<template>
  <div
    class="image-container"
    :class="{ visible: isVisible }"
    :style="{ 'background-image': 'url(' + thumbUrl + ')' }"
    @click="press()"
  >
    <p class="image-caption" @click.stop="press(true)">
      <span class="caption-details" v-if="details">
        {{ media.size.w }}x{{ media.size.h }}
      </span>
      {{ media.mediaDate | date }}
    </p>
    <Icon v-if="selected" name="check" size="50" class="image-play"></Icon>
    <Icon
      v-else-if="media.type === 'video'"
      name="play"
      size="50"
      class="image-play"
    ></Icon>
  </div>
</template>

<script>
import { throttle } from "throttle-debounce";

import Icon from "./Icon";

export default {
  name: "Media",
  components: {
    Icon,
  },
  props: {
    media: Object,
    details: Boolean,
    selected: Boolean,
  },
  data() {
    return {
      open: false,
      isVisible: false,
      checkVisibleTimeout: null,
      checkVisibleThrottle: throttle(100, this.checkVisible),
    };
  },
  computed: {
    thumbUrl() {
      return this.isVisible
        ? this.env.apiHost + "/medias" + this.media.thumbnailPath
        : "";
    },
  },
  mounted() {
    this.checkVisible();
    if (!this.isVisible) {
      window.addEventListener("scroll", this.checkVisibleThrottle, true);
      window.addEventListener("resize", this.checkVisibleThrottle, true);
    }
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    checkVisible() {
      if (!this.isVisible) {
        let rect = this.$el.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          this.isVisible = true;
          this.removeListeners();
        }
      }
    },
    removeListeners() {
      window.removeEventListener("scroll", this.checkVisibleThrottle, true);
      window.removeEventListener("resize", this.checkVisibleThrottle, true);
    },
    press(caption) {
      this.$emit("press", caption);
    },
  },
};
</script>
