<template>
  <div class="view">
    <div class="view-inner">
      <div
        v-for="media in pending"
        :key="media._id"
        class="image-container visible"
      >
        <Media :media="media" details />
        <div class="media-controls">
          <Icon
            name="trash"
            size="30"
            @click="deleteMedia(media)"
            class="media-control delete-media"
          />
          <Icon
            name="check"
            size="30"
            @click="validateMedia(media)"
            class="media-control validate-media"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/Icon.vue";
import Media from "../components/Media.vue";

export default {
  name: "Pending",
  components: {
    Icon,
    Media: Media,
  },
  data() {
    return {
      pending: null,
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.api.Media.query({ status: "pending" }).then((pending) => {
        this.pending = pending.medias;
      });
    },
    deleteMedia(media) {
      if (confirm("Are you sure you want to delete this file?")) {
        this.api.Media.destroy(media._id)
          .then(() => {
            this.refresh();
            alert("Successfully deleted");
          })
          .catch((err) => {
            alert("Delete error: " + err);
          });
      }
    },
    validateMedia(media) {
      if (confirm("Are you sure you want to accept this file?")) {
        media.status = "active";
        this.api.Media.update(media)
          .then(() => {
            this.refresh();
            alert("Media validated");
          })
          .catch((err) => {
            alert("Validate error: " + err);
          });
      }
    },
  },
};
</script>
