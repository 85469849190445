<template>
  <div class="duplicates">
    <div
      class="duplicate-line"
      v-for="(duplicate, index) in duplicates"
      :key="index"
    >
      <div class="duplicate-media" v-for="(media, mediaIndex) in duplicate" :key="media._id">
        <Media :media="media" details @press="open(duplicate, mediaIndex)"></Media>
        <div class="media-controls">
          <Icon
            name="trash"
            size="30"
            @click="deleteMedia(media)"
            class="media-control delete-media"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/Icon.vue";
import Media from "../components/Media.vue";

export default {
  name: "Duplicates",
  components: {
    Icon,
    Media: Media,
  },
  data() {
    return {
      duplicates: null,
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.api.Media.getDuplicates().then((duplicates) => {
        this.duplicates = duplicates;
      });
    },
    open(duplicate, mediaIndex) {
      this.$root.$emit("open-media", {
        medias: duplicate,
        index: mediaIndex,
      });
    },
    deleteMedia(media) {
      if (confirm("Are you sure you want to delete this file?")) {
        this.api.Media.destroy(media._id)
          .then(() => {
            this.refresh();
            alert("Successfully deleted");
          })
          .catch((err) => {
            alert("Delete error: " + err);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.duplicates {
  overflow: auto;

  .duplicate-line {
    display: flex;
    flex-direction: row;
  }

  .duplicate-media {
    position: relative;
    flex: 1;
  }
}
</style>
